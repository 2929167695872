// All User Types
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

// API Loader Types
export const SET_API_CALL_START = "SET_API_CALL_START";
export const SET_API_CALL_END = "SET_API_CALL_END";

// List of all API URL's
export const USER_LOGIN_URL = "/users/login";
export const TRANSACTION_SUMMARY = "/transcription/summary";

// Progress
export const SET_PROGRESS_SHOW = "SET_PROGRESS_SHOW";
export const SET_PROGRESS_PAUSE = "SET_PROGRESS_PAUSE";


