import { LOGIN_SUCCESS, LOGOUT } from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS: {
      // window.localStorage.setItem("token", payload.token);
      window.localStorage.setItem("user", JSON.stringify(payload));
      return {
        ...state,
        isAuthenticated: true,
        userDetails: payload,
      };
    }
    case LOGOUT: {
      window.localStorage.removeItem("token");
      return {
        ...state,
        isAuthenticated: false,
        userDetails: {},
      };
    }

    default:
      return state;
  }
}
