import { SET_PROGRESS_PAUSE, SET_PROGRESS_SHOW } from "../actions/types";

const initialState = {
    show: false,
    isPause: false
};


export default function (state = initialState, action) {
    const { type } = action;
    switch (type) {
        case SET_PROGRESS_PAUSE:
            return {
                ...state,
                isPause: action.payload
            };
        case SET_PROGRESS_SHOW:
            return {
                ...state,
                show: action.payload
            };
        default:
            return state;
    }
}