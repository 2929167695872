import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers/index";
import { thunk } from "redux-thunk";

const initialState = {};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk), // Use a function to specify middleware
  preloadedState: initialState,
});

export default store;
