import { Backdrop, Box, CircularProgress, styled } from "@mui/material";
import React from "react";
import { connect } from "react-redux";

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2000,
  color: "#fff",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
}));

const LoaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
});

const FullScreenLoader = ({ isAPICall }) => {
  if (!isAPICall) return null; // Return null instead of an empty string
  return (
    <StyledBackdrop open={isAPICall}>
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    </StyledBackdrop>
  );
};

const mapStateToProps = (state) => ({
  isAPICall: state.apiCall.isAPICall,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FullScreenLoader);
