import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FullScreenLoader from "./Loader/FullScreenLoader";

// Use React.lazy to import components
const Login = lazy(() => import("./Login/Login"));
const Auth = lazy(() => import("./Auth/auth"));
const DashboardLayoutAccount = lazy(() => import("./Dashboard/Dashboard"));

const AllRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<FullScreenLoader />}>
        {/* Fallback UI while loading */}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route element={<Auth />}>
          {/* <Route> */}
            <Route path="/dashboard" element={<DashboardLayoutAccount />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AllRoutes;
