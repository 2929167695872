import { combineReducers } from "redux";
import auth from "./auth";
import apiCall from "./apiCall";
import progress from "./progress";

export default combineReducers({
  auth,
  apiCall,
  progress
});
