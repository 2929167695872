import AllRoutes from "./Components/Routes";
import { createTheme } from "@mui/material";
import { getDesignTokens } from "./Theme/theme";
import { AppProvider } from "@toolpad/core";
import { Provider } from "react-redux";
import store from "./StateManagement/store/store";
import FullScreenLoader from "./Components/Loader/FullScreenLoader";

function App() {
  const calculatedMode = "light";
  const brandingDesignTokens = getDesignTokens(calculatedMode);
  // preview-start
  const THEME = createTheme({
    ...brandingDesignTokens,
    palette: {
      ...brandingDesignTokens.palette,
      mode: calculatedMode,
    },
  });
  // preview-end
  return (
    <Provider store={store}>
      <AppProvider theme={THEME}>
        <AllRoutes />
        <FullScreenLoader />
      </AppProvider>
    </Provider>
  );
}

export default App;
