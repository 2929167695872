import { SET_API_CALL_END, SET_API_CALL_START } from "../actions/types";

const initialState = {
  isAPICall: false
};

export default function(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case SET_API_CALL_START:      
      return {
        ...state,
        isAPICall: true
      };
    case SET_API_CALL_END:
      return {
        ...state,
        isAPICall: false
      };
    default:
      return state;
  }
}
